<template>
  <section class="message" :class="{ my: createdBy === email }">
    <em class="label">{{ createdBy }}, {{ new Date(createdAt).toLocaleString() }}</em>
    <div>
      <span class="body">
        {{ message }}
      </span>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    createdBy: {
      type: String,
      required: true
    },
    createdAt: {
      type: Date,
      required: true
    },
    metadata: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    ...mapState({
      email: s => s.identity.email
    })
  }
};
</script>

<style lang="scss" scoped>
.message {
  height: 100%;
  width: 100%;
  padding: 0.75rem 1rem 0.75rem 1.25rem;
  padding-right: 25%;

  &.my {
    text-align: right;
    padding-left: 25%;
    padding-right: 5px;
  }

  .label {
    font-size: 0.7rem;
    font-style: italic;
    color: var(--theme-on-background-accent);
  }
  .body {
    display: inline-block;
    background-color: var(--theme-surface);
    padding: 9px;
    border-radius: 6px;
    text-align: left;
    line-height: 1.3rem;
  }
}
</style>
